import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import TwitterSvg from "./TwitterSvg"

const Header = () => {
  return (
    <NavHeader>
      <NavWrapper>
        <Nav>
          <NavLeft>
            <NavList>
              <MenuLink to="/blog">Blog</MenuLink>
              {/* <MenuLink to="/">Tutorial</MenuLink> */}
            </NavList>
          </NavLeft>
          <NavCenter>
            <SiteTitle>
              <MenuLink to="/">TechEffect</MenuLink>
            </SiteTitle>
          </NavCenter>
          <NavRight>
            <TwitterSvg />
          </NavRight>
        </Nav>
      </NavWrapper>
    </NavHeader>
  )
}

export default Header

const NavHeader = styled.header`
  position: fixed;
  z-index: 10;
  width: 100%;
  background-color: #61dafb;
  color: white;
  font-family: "roboto";
`
const NavWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`
const Nav = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
`
const NavLeft = styled.div`
  flex: 1;
  font-size: 1em;
`
const NavCenter = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`
const NavRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`
const SiteTitle = styled.div`
  font-weight: 700;
  font-size: 1.2em;
`
const NavList = styled.div``

const MenuLink = styled(Link)`
  text-decoration: none;
  color: white;
  padding: 4px 8px;
  font-family: "roboto";
  transition: opacity 0.3s ease-in-out;
  &:hover {
    opacity: 0.5;
  }
`
