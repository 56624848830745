import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const NavFooter = styled.footer`
  background-color: #61dafb;
  color: white;
  margin-top: auto;
`
const NavWrapper = styled.div`
  margin: 0 auto;
  @media (min-width: 768px) {
    max-width: 1000px;
  }
`

const Nav = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 150px;
  padding-right: 8px;
`

const InternalLink = styled(Link)`
  text-decoration: none;
  margin-right: 0.5em;
  color: white;
  font-family: "roboto";
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 0.5;
  }
`

const Footer = () => {
  return (
    <NavFooter>
      <NavWrapper>
        <Nav>
          <InternalLink to="/about">TechEffect</InternalLink>
          <span>on Gatsby</span>
        </Nav>
      </NavWrapper>
    </NavFooter>
  )
}

export default Footer
